import React from 'react'
import { Toast } from 'primereact/toast'
import './ToastDemo.css'
import ErrorIcon from '../../../assets/img/toast/error.svg'
import SuccessIcon from '../../../assets/img/toast/success.svg'

type ToastProps = {
  toast?: any
  baseZIndex?: number
}

export const Content = (message?: string, type?: string) => {
  const style = {
    width: '30px',
    height: '30px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
  return (
    <div className='p-flex p-flex-column' style={{ flex: '1' }}>
      <div className='p-text-center' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        {/* <i className="pi pi-exclamation-triangle" style={{ fontSize: '3rem' }}></i> */}

        {type === 'error' ? (
          <div style={{ ...style, background: 'red' }}>
            <img src={ErrorIcon} alt='icon' />
          </div>
        ) : type === 'success' ? (
          <div style={{ ...style, background: 'green' }}>
            <img src={SuccessIcon} alt='icon' />
          </div>
        ) : (
          <div style={{ ...style, background: 'orange' }}>
            <img src={ErrorIcon} alt='icon' />
          </div>
        )}
        <div>{message && <p style={{ margin: '0px 0px 0px 0px', padding: '8px' }}>{message}</p>}</div>
      </div>
    </div>
  )
}

const ToastComponent = (props: ToastProps) => {
  const { toast, baseZIndex } = props
  const clear = () => {
    toast.current.clear()
  }

  return <Toast ref={toast} baseZIndex={baseZIndex} />
}

export default ToastComponent
