import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { Spinner } from '../UI'
import { postRequest } from '../../services/HttpService/HttpService'
import { AuthService } from '../../services/AuthService/AuthService'
import * as RouteConsts from '../../navigation/CONSTANTS'
import { GlobalDataService } from '../../services/GlobalDataService/GlobalDataService'
import { NavigateUserService } from '../../services/NavigateUserService/NavigateUserService'
import { TokenService } from '../../services/TokenService/TokenService'
import { ToasterService } from '../../services/ToasterService/ToasterService'
export const ProtectedRoute: React.FC<{
  component: React.FC
  path: string
  exact?: boolean
}> = (props) => {
  const [isAuthenticated, setisAuthenticated] = useState(2)
  const history = useHistory()

  const isMountRef = useRef(false)

  // const navigateUsers = (userData: any) => {
  //     if (userData.iscampus && !userData.isregistered) {
  //         history.push(RouteConsts.CAMPUSREGISTER);
  //     } else if (!userData.iscampus && !userData.isregistered) {
  //         history.push(RouteConsts.CANDIDATEREGISTER);
  //     } else if (userData.iscampus && userData.isregistered && !userData.status) {
  //         history.push(RouteConsts.CAMPUSAPPROVAL);
  //     } else if (userData.isregistered && userData.status) {
  //         history.push(RouteConsts.HOME);
  //     } else {
  //         history.push(RouteConsts.LOGIN);
  //     }
  // };
  useEffect(() => {
    isMountRef.current = true
    if (TokenService().getAccessToken()) {
      postRequest('/vt', {})
        .then((response: any) => {
          const userData = JSON.parse(JSON.stringify(response.data.data))
          if (userData.status === 0) {
            history.push(RouteConsts.LOGIN)
            return ToasterService.show(`Rejected by Admin`, 'error')
          } else if (!userData.isverified) {
            const domain = TokenService().getDomain()
            if (domain) {
              history.push(RouteConsts.EMPLOYEE)
            } else {
              localStorage.setItem('signupuseremail', userData.email)
              history.push(RouteConsts.VERIFYCODE)
            }
          } else {
            AuthService.userInfo.next(userData)
            GlobalDataService.getCountryData()
            isMountRef.current && setisAuthenticated(1)
            history.replace(NavigateUserService.getRoute())
          }

          // if (userData.iscampus && !userData.isregistered && props.path === RouteConsts.CAMPUSREGISTER)
          //     // isMountRef.current && setisAuthenticated(1);
          // else if (
          //     !userData.iscampus &&
          //     !userData.isregistered &&
          //     props.path === RouteConsts.CANDIDATEREGISTER
          // )
          //     // isMountRef.current && setisAuthenticated(1);
          // else if (
          //     userData.iscampus &&
          //     userData.isregistered &&
          //     !userData.status &&
          //     props.path === RouteConsts.CAMPUSAPPROVAL
          // )
          //     // isMountRef.current && setisAuthenticated(1);
          // else if (userData.isregistered && userData.status && props.path === RouteConsts.HOME)
          //     // isMountRef.current && setisAuthenticated(1);
          // else
          // if (
          //     props.path === RouteConsts.HOME ||
          //     props.path === RouteConsts.CAMPUSREGISTER ||
          //     props.path === RouteConsts.CANDIDATEREGISTER ||
          //     props.path === RouteConsts.CAMPUSAPPROVAL
          // ) {
          //     // navigateUsers(userData);
          //     // isMountRef.current && setisAuthenticated(1);
          //     history.replace(NavigateUserService.getRoute());
          // }
          // else {
          //     isMountRef.current && setisAuthenticated(2);
          //     history.push(RouteConsts.LOGIN);
          // }
        })
        .catch((error: any) => {
          isMountRef.current && setisAuthenticated(2)
          // if (error && error.response && error.response.data && error.response.data.message)
          //     ToasterService.show(error.response.data.message, 'error');
          history.push(RouteConsts.LOGIN)
        })
    } else {
      isMountRef.current && setisAuthenticated(2)
      history.push(RouteConsts.WELCOME_AS_CANDIDATE)
      ToasterService.show('Authorization Failure', 'error')
    }

    return () => {
      isMountRef.current = false
    }
  }, [])

  return isMountRef.current && isAuthenticated === 1 ? (
    <Route
      path={props.path}
      exact={props.exact}
      render={() => (
        <Suspense fallback={<div>Loading....</div>}>
          <props.component></props.component>
        </Suspense>
      )}
    />
  ) : (
    <Spinner />
  )
}
