import React, { Suspense } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { Spinner } from '../UI'
import { LOGIN } from '../../navigation/CONSTANTS'

export const ConditionalProtectedRoute: React.FC<{
  component: React.FC
  path: string
  exact: boolean
}> = (props) => {
  return localStorage.getItem('signupuseremail') ? (
    <Route
      path={props.path}
      exact={props.exact}
      render={() => (
        <Suspense fallback={<Spinner />}>
          <props.component></props.component>
        </Suspense>
      )}
    />
  ) : (
    <Redirect to={LOGIN} />
  )
}
