import * as RouteConsts from '../../navigation/CONSTANTS'
import * as CampusRouteConst from '../../pages/Campus-Layout/CampusLayoutRouterCONSTANTS'
import * as LayoutRouterConst from '../../pages/Layout/LayoutRouterCONSTANTS'
import { AuthService } from '../AuthService/AuthService'

export const NavigateUserService = {
  getRoute: () => {
    const userInfo: any = AuthService.userInfo.value
    let navRoute = null
    if (userInfo) {
      if (userInfo.iscampus && !userInfo.isregistered) {
        navRoute = RouteConsts.CAMPUSREGISTERINFO
      } else if (!userInfo.iscampus && !userInfo.isregistered) {
        navRoute = RouteConsts.CANDIDATEREGISTER
      } else if (userInfo.iscampus && userInfo.isregistered && !userInfo.status) {
        navRoute = RouteConsts.CAMPUSAPPROVAL
      } else if (userInfo.isregistered && userInfo.status) {
        const currentpath = window.location.pathname
        if (
          currentpath === RouteConsts.CAMPUSREGISTER ||
          currentpath === RouteConsts.CANDIDATEREGISTER ||
          currentpath === RouteConsts.CAMPUSAPPROVAL ||
          currentpath === RouteConsts.LOGIN ||
          currentpath === RouteConsts.SIGNUP ||
          currentpath === '/signup?c2cSignup' ||
          currentpath === '/socialauth'
        ) {
          if (userInfo.iscampus) {
            navRoute = RouteConsts.CAMPUSLAYOUT
          } else {
            navRoute = LayoutRouterConst.HOME
          }
        } else {
          if (userInfo.iscampus) {
            if (window.location.pathname.startsWith(RouteConsts.CAMPUSLAYOUT)) {
              navRoute = window.location.pathname + window.location.search
            } else {
              navRoute = RouteConsts.CAMPUSLAYOUT
            }
          } else {
            if (
              (window.location.pathname.startsWith(RouteConsts.CAMPUSLAYOUT) ||
                window.location.pathname.startsWith(RouteConsts.SUPERADMIN)) &&
              userInfo.currentRoleCode === 'Candidate'
            ) {
              navRoute = LayoutRouterConst.HOME
            } else {
              navRoute = window.location.pathname
            }
          }
        }
      } else if (userInfo.isregistered && userInfo.isverified) {
        const currentpath = window.location.pathname
        if (
          currentpath === RouteConsts.CAMPUSREGISTER ||
          currentpath === RouteConsts.CANDIDATEREGISTER ||
          currentpath === RouteConsts.CAMPUSAPPROVAL ||
          currentpath === RouteConsts.LOGIN
        ) {
          // role based navigation
          if (userInfo.role && userInfo.role === 'Superadmin') {
            navRoute = RouteConsts.SUPERADMIN
            //navRoute = RouteConsts.EMPLOYEE;
          } else {
            navRoute = RouteConsts.LOGIN
          }
        } else {
          if (userInfo.role && userInfo.role === 'Superadmin') {
            if (window.location.pathname.startsWith(RouteConsts.SUPERADMIN)) {
              navRoute = window.location.pathname + window.location.search
            } else {
              navRoute = RouteConsts.SUPERADMIN
            }
            // if (window.location.pathname.startsWith(RouteConsts.EMPLOYEE)) {
            //   navRoute = window.location.pathname;
            // } else {
            //   navRoute = RouteConsts.LOGIN;
            // }
          } else navRoute = window.location.pathname
        }
      } else {
        navRoute = RouteConsts.LOGIN
      }
    } else {
      navRoute = RouteConsts.LOGIN
    }
    return navRoute
  },

  getDefaultRoute: () => {
    const userInfo: any = AuthService.userInfo.value
    let navRoute = ''
    if (userInfo?.assign_modules_name) {
      if (userInfo.assign_modules_name.includes('Dashboard')) {
        navRoute = CampusRouteConst.DASHBOARD
      } else if (userInfo.assign_modules_name.includes('Analytics')) {
        navRoute = CampusRouteConst.ANALYTICS
      } else if (userInfo.assign_modules_name.includes('Campus Details')) {
        navRoute = CampusRouteConst.EDITCAMPUSPROFILE
      } else if (userInfo.assign_modules_name.includes('Candidates')) {
        navRoute = CampusRouteConst.CANDIDATES
      } else if (userInfo.assign_modules_name.includes('Invite Companies')) {
        navRoute = CampusRouteConst.INVITES
      } else if (userInfo.assign_modules_name.includes('Verification')) {
        navRoute = CampusRouteConst.VERIFICATION
      } else if (userInfo.assign_modules_name.includes('Users')) {
        navRoute = CampusRouteConst.USERS
      }
    }
    return navRoute
  },
}
