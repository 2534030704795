import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { LOGIN } from './navigation/CONSTANTS'
import { ToasterService } from './services/ToasterService/ToasterService'
import { TokenService } from './services/TokenService/TokenService'

let baseURL = `${process.env.REACT_APP_API_BASEURL}/v1`

export let axiosInstance: any
//const context: any = JSON.parse(localStorage.getItem('config') || "");
axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    'x-origin': 'VegaC2C',
    'x-api-key': 'fFbeG9rZWabqGJ64GQd4jRKCd4EqgrEg',
  },
})

axiosInstance.interceptors.request.use(
  (config: any) => {
    const token = TokenService().getAccessToken()
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    const domain = TokenService().getDomain()
    if (domain) {
      config.headers['x-subdomain'] = domain
    }
    return config
  },
  (error: any) => {
    Promise.reject(error)
  },
)

export const getAxiosInstance = async (data?: any) => {
  axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
      'x-origin': 'VegaC2C',
      'x-subdomain': data.xSubdomin,
      'x-api-key': 'fFbeG9rZWabqGJ64GQd4jRKCd4EqgrEg',
      Authorization: `Bearer ${data.token}`,
    },
  })
}
