import React, { useState } from 'react'
import { FormType } from '../../schemas/FormField'
interface RouteParams {
  id?: string
}
export const DynamicIdComponent: React.FC = () => {
  const [registerForm, setRegisterForm] = useState<FormType>({
    firstname: {
      inputType: 'inputtextarea',
      label: 'First Name',
      value: null,
      validation: {
        required: true,
        minlength: 2,
        maxlength: 100,
      },
      rows: 5,
      cols: 85,
    },
    lastname: {
      inputType: 'inputtext',
      label: 'Last Name',
      value: null,
      validation: {
        required: true,
        minlength: 2,
        maxlength: 100,
      },
    },
    contactnumber: {
      inputType: 'contactnumber',
      label: 'Contact Number',
      value: null,
      validation: {
        required: true,
        maskFixedLength: 10,
      },
    },
    email: {
      inputType: 'inputtext',
      label: 'Email',
      value: null,
      validation: {
        required: true,
        email: true,
        minlength: 2,
        maxlength: 100,
      },
    },
    gender: {
      inputType: 'singleSelect',
      label: 'Gender',
      value: null,
      validation: {
        required: true,
      },
      options: [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
        { label: 'Others', value: 'Others' },
      ],
    },
    dob: {
      inputType: 'singleDatePicker',
      label: 'Date Of Birth',
      value: null,
      validation: {
        required: true,
      },
    },
    currentState: {
      inputType: 'inputtext',
      label: 'Current State',
      value: null,
      validation: {
        required: true,
        minlength: 2,
        maxlength: 100,
      },
    },
    currentCity: {
      inputType: 'inputtext',
      label: 'Current City',
      value: null,
      validation: {
        required: true,
        minlength: 2,
        maxlength: 100,
      },
    },
    commAddress: {
      inputType: 'inputtext',
      label: 'Communication Address',
      value: null,
      validation: {
        required: true,
        minlength: 2,
        maxlength: 500,
      },
    },
    pincode: {
      inputType: 'inputMask',
      label: 'Pincode',
      value: null,
      customMask: '999999',
      validation: {
        required: true,
        maskFixedLength: 6,
      },
    },
    ifrelocate: {
      inputType: 'inputSwitch',
      label: 'Open to relocate?',
      value: true,
    },
  })

  const [isFormValid, setisFormValid] = useState(true)

  return (
    // <div>
    //     Dynamic id component.add query params and fragment to URL.
    //     <p>Check cosole for params info.</p>
    //     {/* using input component */}
    //     <h3>USING INPUT TEXT</h3>
    //     <InputComponent inputtype="inputtext" label="Name" key="name" id="name" />
    //     {/* using calendar component */}
    //     <h3>USING CALENDAR SINGLE DATE</h3>
    //     <CalendarComponent inputtype="singleDatePicker" label="Single Date" key="singledate" id="singledate" />
    //     {/* using form */}
    //     <h3>USING FORM</h3>
    //     <FormComponent fieldArray={fields} />
    // </div>inputtext
    <div>
      {/* <FormComponent
                form={registerForm}
                formUpdateEvent={updatedFormHandler}
                isFormValidFlag={isFormValid}
            ></FormComponent>
            <button onClick={submitHandler}>Submit</button> */}

      {/* <FileUploads
                mode="basic"
                multiple={false}
                accept={allowedformat}
                maxFileSize={1}
                label={{ chooseLabel: 'Browse', uploadLabel: 'Upload' }}
                // url="uploading"
                // uploadEvents={{
                //     uploadHandler: { fileUpLoader },
                // }}
                file={fileHandler}
            ></FileUploads> */}

      {/* <FileUploads
                mode="advanced"
                multiple={true}
                accept={allowedformat}
                maxFileSize={10000000}
                displaySize="1mb"
                label={{ chooseLabel: 'Multiple Browse', uploadLabel: 'Upload' }}
                file={fileHandler}
                uploadonclick={fileUpLoader}
            ></FileUploads> */}

      <div>
        {/* button of default type */}
        {/* <ButtonComponent
                    label="Default"
                    width={btnwidth}
                    apihitting={btnapihitting}
                    submitEvent={onSubmitHandler}
                ></ButtonComponent> */}

        {/* Success button */}
        {/* <ButtonComponent
                    label="Success"
                    width={btnwidth}
                    type="success"
                    apihitting={btnapihitting}
                    submitEvent={onSubmitHandler}
                ></ButtonComponent> */}

        {/* danger button  */}
        {/* <ButtonComponent
                    label="Danger"
                    width={btnwidth}
                    type="danger"
                    submitEvent={onSubmitHandler}
                ></ButtonComponent> */}
      </div>
    </div>
  )
}
