import React, { useEffect, useRef } from 'react'
import { Subscription } from 'rxjs'
import './App.css'
import { ErrorBoundary } from './components/UI'
import { RouterConfig } from './navigation/RouterConfig'
import { ToasterService } from './services/ToasterService/ToasterService'
// import {Spinner} from './components';
//import { Toast } from 'primereact/toast';
import Toast from './components/UI/Toast/Toast'

export const App: React.FC = () => {
  const toastVar: any = useRef(null)
  const isMountRef = useRef(false)

  useEffect(() => {
    isMountRef.current = true
    let subscription: Subscription

    if (isMountRef.current) {
      subscription = ToasterService.getMessage().subscribe((message) => {
        if (message) {
          toastVar.current.show(message)
        }
      })
    }
    return () => {
      isMountRef.current = false
      subscription.unsubscribe()
    }
  }, [])

  return (
    <ErrorBoundary>
      <meta name='viewport' content='width=device-width, initial-scale=1'></meta>

      <RouterConfig />
      {/* <Spinner /> */}
      <Toast toast={toastVar} baseZIndex={3000} />
    </ErrorBoundary>
  )
}
