import React, { Component } from 'react'

/**
 * ErrorBoundary will ensure that something is rendered in your react application
 * if any of the child components within it throws an unexpected error.
 * The `render` prop expects a function that returns a React Element to
 * display in the event of an error. It is passed the error.
 *
 * NOTE: This is only a 'class component' because there is currently no functional
 * equivalent for componentDidCatch.
 */
// import packageJson from '../../../package.json';
// const latestVersion = packageJson.version;
// const semverGreaterThan = (latestVersion: any, prevVersion: any) => {
//   const versionsA = latestVersion.split(/\./g);
//   const versionsB = prevVersion.split(/\./g);
//   while (versionsA.length || versionsB.length) {
//     const a = Number(versionsA.shift());

//     const b = Number(versionsB.shift());
//     // eslint-disable-next-line no-continue
//     if (a === b) continue;
//     // eslint-disable-next-line no-restricted-globals
//     return a > b || isNaN(b);
//   }
//   return false;
// };
export class ErrorBoundary extends Component {
  state = {
    hasError: false,
    errorMessage: '',
    // isLatestVersion: false,
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error(error, errorInfo)
    this.setState({ hasError: true, errorMessage: error })
  }

  // componentDidMount() {
  //   fetch('/meta.json')
  //     .then((response:any) => response.json())
  //     .then((meta) => {
  //       const prevVersion = meta.version;
  //       const shouldForceRefresh = semverGreaterThan(latestVersion, prevVersion);
  //       if (shouldForceRefresh) {
  //         this.setState({ isLatestVersion: true });
  //       } else {
  //         this.setState({ isLatestVersion: false });
  //       }
  //     });
  // }

  render() {
    // if (this.state.isLatestVersion) {
    // You can decide how and when you want to force reload
    // refreshCacheAndReload();
    // if (caches) {
    //   // Service worker cache should be cleared with caches.delete()
    //   caches.keys().then(function (names) {
    //     for (let name of names) caches.delete(name);
    //   });
    // }
    // delete browser cache and hard reload
    // window.location.reload();
    // } else
    if (this.state.hasError) {
      return <h1>{this.state.errorMessage}</h1>
    }

    return this.props.children
  }
}
