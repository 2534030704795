import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { axiosInstance } from './axiosCongiurable'
import { LOGIN } from './navigation/CONSTANTS'
import { ToasterService } from './services/ToasterService/ToasterService'
import { TokenService } from './services/TokenService/TokenService'

let baseURL = `${process.env.REACT_APP_API_BASEURL}/v1`
export let AxiosInstance: any

AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASEURL}/v1`,
})

export const defaultAxiosInstance = () => {
  AxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_BASEURL}/v1`,
    headers: {
      'x-api-key': 'fFbeG9rZWabqGJ64GQd4jRKCd4EqgrEg',
    },
    // transformRequest: (data, headers) => {
    //   // Remove all shared headers
    //   //delete headers.common;
    //   // or just the auth header
    //   delete headers.common.Authorization;
    // },
  })
}

export const getConfigAxiosInstance = async (data?: any) => {
  const { xSubdomin, token, xOrigin = 'VegaC2C' } = data
  let headers: {} = {}
  if (xSubdomin) {
    headers = {
      'x-origin': xOrigin,
      'x-subdomain': data.xSubdomin,
      'x-api-key': 'fFbeG9rZWabqGJ64GQd4jRKCd4EqgrEg',
      Authorization: `Bearer ${data.token}`,
    }
  } else {
    headers = {
      'x-api-key': 'fFbeG9rZWabqGJ64GQd4jRKCd4EqgrEg',
      Authorization: `Bearer ${data.token}`,
    }
  }

  AxiosInstance = axios.create({
    baseURL: baseURL,
    headers: headers,
  })
}

AxiosInstance.defaults.headers.common['x-api-key'] = 'fFbeG9rZWabqGJ64GQd4jRKCd4EqgrEg'
AxiosInstance.interceptors.request.use(
  (config: any) => {
    //    const token = localStorageService.getAccessToken();
    //    const token = LocalStorageService.getAccessToken();
    const token = TokenService().getAccessToken()
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    const rcode = TokenService().getRcode()
    if (rcode) {
      config.headers['rcode'] = rcode
    }
    const domain = TokenService().getDomain()
    if (domain) {
      config.headers['x-subdomain'] = domain
    }
    const origin = TokenService().getOrigin()
    if (origin) {
      config.headers['x-origin'] = origin
    }
    // config.headers['Content-Type'] = 'application/json';
    return config
  },
  (error: any) => {
    Promise.reject(error)
  },
)

AxiosInstance.interceptors.response.use(
  (response: any) => {
    return response
  },
  (error: any) => {
    // const originalRequest = error.config;
    if (error?.response?.data?.message && typeof error.response.data.message === 'string') {
      ToasterService.show(error.response.data.message, 'error')
    }

    if (error?.response?.status === 401 || error?.response?.status === 401) {
      useHistory().replace(LOGIN)
    }
    // if (error.response.status === 400) {
    //    router.push('/login');
    // return Promise.reject(error);
    // }

    // if (error.response.status === 401 && originalRequest.url === 'http://13.232.130.60:8081/v1/auth/token') {
    //    router.push('/login');
    //    return Promise.reject(error);
    // }

    // if (error.response.status === 401 && !originalRequest._retry) {
    //    originalRequest._retry = true;
    //    const refreshToken = localStorageService.getRefreshToken();
    //    return axios.post('/auth/token',
    //        {
    //            "refresh_token": refreshToken
    //        })
    //        .then((res:any) => {
    //            if (res.status === 201) {
    //                localStorageService.setToken(res.data);
    //                axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
    //                return axios(originalRequest);
    //            }
    //        })
    // }
    return Promise.reject(error)
  },
)
