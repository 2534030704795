export const WELCOME = '/welcome'
export const WELCOME_AS_CANDIDATE = '/v/welcome'
export const WELCOME_ALL = '/welcome-all-openings'
export const SIGNUP = '/signup'
export const JOBFAIR = '/jobfair'
export const DASHBOARD = '/dashboard'
export const LOGIN = '/login'
export const LOGINASEMPLOYEE = '/login/employee'
export const ROLELOGIN = '/v/invitation'
export const VERIFYCODE = '/verifycode'
export const FORGETPASSWORD = '/forgetpassword'
export const RESETPASSWORD = '/v/reset'
export const CANDIDATEREGISTER = '/candidate/register'
export const CAMPUSREGISTER = '/campus/register'
export const CAMPUSREGISTERINFO = '/campus/registerinfo'
export const HOME = '/home'
export const CANDIDATEPERSONALINFO = '/cPersonalInfo'
export const CANDIDATEEDUCATIONINFO = '/cEducationInfo'
export const CAMPUSAPPROVAL = '/campus/approval'
export const CAMPUSSIGNUP = '/campus/signup'
export const CAMPUSLAYOUT = '/campus'
export const SUPERADMIN = '/superadmin'
export const CANDIDATELAYOUT = '/'
export const TEST = '/quest'
export const EMPLOYEE = '/employee'
export const CONTESTREGISTER = '/contest-register'
export const CONTESTREGISTERUSER = '/contest-register-user'
export const RESUMEPAGE = '/resume'
export const UNSUBSCRIBE = '/unsubscribe'
export const OTPPAGE = '/verification-page'
export const CREATENEWPASSWORD = '/createNewPassword'
export const JOBREGISTERUSER = '/job-register-user'
export const PLAYGAMES = `/contest/play-games`
export const REDIRECTPAGE = `/redirect-page`

// export const REWARD = '/reward';
