import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import { Spinner } from '../UI'
import { postRequest } from '../../utils/PolestarllpApiServices/HTTPservices'
import { AuthService } from '../../services/AuthService/AuthService'
import * as RouteConsts from '../../navigation/CONSTANTS'
import { GlobalDataService } from '../../services/GlobalDataService/GlobalDataService'
import { NavigateUserService } from '../../services/NavigateUserService/NavigateUserService'
import { TokenService } from '../../services/TokenService/TokenService'
import { ToasterService } from '../../services/ToasterService/ToasterService'
import { axiosInstance } from '../../axiosCongiurable'
import axios from 'axios'

export const ProtectedRoutePolestar: React.FC<{
  component: React.FC
  path: string
  exact?: boolean
}> = (props) => {
  const [isAuthenticated, setisAuthenticated] = useState(2)
  const history = useHistory()
  const isMountRef = useRef(false)
  useEffect(() => {
    isMountRef.current = true
    if (TokenService().getAccessToken()) {
      axiosInstance
        .post('/vt')
        .then((response: any) => {
          const userData = JSON.parse(JSON.stringify(response.data.data))
          if (userData) {
            AuthService.userInfo.next(userData)
            isMountRef.current && setisAuthenticated(1)
            history.push(RouteConsts.EMPLOYEE)
          } else {
            isMountRef.current && setisAuthenticated(2)
          }
        })
        .catch((error: any) => {
          isMountRef.current && setisAuthenticated(2)
          // if (error && error.response && error.response.data && error.response.data.message)
          //     ToasterService.show(error.response.data.message, 'error');
          history.push(RouteConsts.LOGIN)
        })
    } else {
      isMountRef.current && setisAuthenticated(2)
      history.push(RouteConsts.WELCOME_AS_CANDIDATE)
    }

    return () => {
      isMountRef.current = false
    }
  }, [])

  return isMountRef.current && isAuthenticated === 1 ? (
    <Route
      path={props.path}
      exact={props.exact}
      render={() => (
        <Suspense fallback={<div>Loading....</div>}>
          <props.component></props.component>
        </Suspense>
      )}
    />
  ) : (
    <Spinner />
  )
}
