import Cookies from 'universal-cookie'

export const TokenService = () => {
  const cookies = new Cookies()

  // var _service;
  // function _getService() {
  //     if(!_service) {
  //       _service = this;
  //       return _service
  //   }
  //   return _service
  // }
  const dateNow = new Date()
  dateNow.setDate(dateNow.getDate() + 7)

  const _setToken = (access_token: string, refresh_token: string) => {
    _clearToken()
    cookies.set('access_token', access_token, { expires: dateNow, path: '/' })
    cookies.set('refresh_token', refresh_token, { expires: dateNow, path: '/' })
  }

  const _setRcode = (rcode: string) => {
    cookies.set('rcode', rcode)
  }

  const _getAccessToken = () => {
    return cookies.get('access_token')
  }

  const _getRefreshToken = () => {
    return cookies.get('refresh_token')
  }

  const _getRcode = () => {
    return cookies.get('rcode')
  }

  const _clearToken = () => {
    cookies.remove('access_token', { path: '/' })
    cookies.remove('refresh_token', { path: '/' })
    cookies.remove('x-origin', { path: '/' })
    cookies.remove('x-domain', { path: '/' })
    cookies.remove('contestId', { path: '/' })
  }

  const _setXDomain = (domain: string) => {
    cookies.set('x-domain', domain, { expires: dateNow, path: '/' })
  }

  const _setOrigin = (origins: string) => {
    cookies.set('x-origin', origins, { expires: dateNow, path: '/' })
  }

  const _setContestID = (id: string) => {
    cookies.set('contestId', id, { expires: dateNow, path: '/' })
  }

  const _getContestId = () => {
    return cookies.get('contestId')
  }
  const _getXDomain = () => {
    return cookies.get('x-domain')
  }

  const _getOrigin = () => {
    return cookies.get('x-origin')
  }

  const _clearOriginDomain = async () => {
    cookies.remove('x-origin')
    cookies.remove('x-domain')
  }

  return {
    //   getService : _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken,
    setRcode: _setRcode,
    getRcode: _getRcode,
    getDomain: _getXDomain,
    setDomain: _setXDomain,
    setOrigin: _setOrigin,
    getOrigin: _getOrigin,
    clearOriginDomain: _clearOriginDomain,
    setContestID: _setContestID,
    getContestId: _getContestId,
  }
}
// export default TokenService;
