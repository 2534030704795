import { InputSwitch } from 'primereact/inputswitch'
import React from 'react'
import styles from './InputSwitch.module.scss'

interface PropsInterface {
  inputtype: 'inputSwitch'
  label?: string | null | undefined
  key: string
  value: boolean | null
  id: string
  changed: any
  blurred: any
  formName?: string
  disable: boolean
  requiredLabel: boolean
  customStyle?: any
}

export const InputSwitchComponent: React.FC<PropsInterface> = (props) => {
  let inputElement = null

  switch (props.inputtype) {
    case 'inputSwitch':
      inputElement = (
        <InputSwitch
          id={props.id + props.formName}
          checked={props.value || false}
          onChange={(e) => props.changed(e.value, props.id)}
          disabled={props.disable}
        />
      )
      break
  }
  return (
    <div style={{ display: 'block' }}>
      <span className={styles.header}>&nbsp;</span>
      <div style={props.customStyle} className={styles.container}>
        {props.label ? (
          <label htmlFor={props.id + props.formName} className={styles.label}>
            {props.label}
          </label>
        ) : null}
        <div className='ms-4 d-flex align-items-center'>{inputElement}</div>
      </div>
    </div>
  )
}

export default InputSwitchComponent
