import { Subject } from 'rxjs'
import { Content } from '../../components/UI/Toast/Toast'

const subject = new Subject()

export const ToasterService = {
  show: (message: string, type?: string, title?: string, toastLife?: number, position?: string) =>
    subject.next({ severity: type || 'success', detail: message, life: toastLife || 3000, content: Content(message, type || 'success') }),
  // clearMessages: () => subject.next(),
  getMessage: () => subject.asObservable(),
}
