import { BehaviorSubject } from 'rxjs'

// const _userInfo = new BehaviorSubject(null);

export const AuthService = {
  // show: (message: string, type?: string, title?: string, toastLife?: number) =>
  //     subject.next({ severity: type || 'success', detail: message, life: toastLife || 3000 }),
  // // clearMessages: () => subject.next(),
  // getMessage: () => subject.asObservable(),
  userInfo: new BehaviorSubject<any>({}),
  applyId: new BehaviorSubject<any>({}),
}
