import { AxiosInstance } from '../../axios'
export const postRequest = (url: string, body: any) => {
  return AxiosInstance.post(url, body)
}
export const deleteRequest = (url: string, body?: any) => {
  return AxiosInstance.delete(url, body)
}
export const getRequest = (url: string, options?: any) => {
  return AxiosInstance.get(url, options)
}

export const putRequest = (url: string, body?: any) => {
  return AxiosInstance.put(url, body)
}
export const postFormDataRequest = (url: string, formData: any) => {
  // AxiosInstance.interceptors.request.use(
  //   (config) => {
  //       config.headers['Content-Type'] = 'multipart/form-data';
  //       return config;
  //   },
  //   (error:any) => {
  //       Promise.reject(error);
  //   },
  // AxiosInstance({
  //   method: 'post',
  //   url: 'myurl',
  //   data: bodyFormData,
  //   headers: {'Content-Type': 'multipart/form-data' }
  //   })
  //   .then(function (response) {
  //       //handle success
  //   })
  //   .catch(function (response) {
  //       //handle error
  //   });
}
