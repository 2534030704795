export const MYJOBS = '/myjobs'
export const APPLIEDJOBS = '/appliedjobs'
export const CONTEST = '/contest'
export const CONTESTDETAIL = '/contest-detail'
export const CONTESTEDITDETAIL = '/contest-edit'
export const CONTESTREGISTER = CONTESTDETAIL + '/register'
export const CONTESTVIEW = '/contest-view'
export const EDITPERSONALINFO = '/profile/edit-personal-info'
export const EDITEDUCATIONALINFO = '/profile/edit-educational-info'
export const JOBDESCRIPTION = `${MYJOBS}/job-description`
export const MYRESUME = '/documents'
export const REWARDS = '/rewards-redeem'
export const REWARDSVIEW = '/rewards'
export const HOME = '/home'
export const DETAIL = '/home-detail'
export const HOMEVIEW = '/home-view'
export const MYREGISTRATION = '/home-my-registrations'
export const MYFAVOURITE = '/home-my-favourite'
export const REGISTRATIONDETAIL = '/home-registration-detail'
export const RESUMEBUILDER = '/resume-builder'
export const RESUMECREATION = `${RESUMEBUILDER}/create-resume`
export const PREMIUMSUBSCRIPTION = `/home-get-premium`
export const PAYMENTCHECKOUT = `/payment-checkout`
export const USERTRANSACTION = `/user-transaction`
export const GAMES = '/games'
export const TIMELINE = '/timeline'

// export const EDITCAMPUSPROFILE = '/edit-campus-details';
