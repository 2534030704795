import React from 'react'
import classes from './Spinner.module.scss'
import mainIcon from '../../../assets/img/main.gif'

export const Spinner: React.FC = () => {
  return (
    <div className={classes['loader-container']}>
      <img className={classes['main-loader']} src={mainIcon} />
      <p className={classes['waitcls']}>Please Wait ...</p>
    </div>
  )
}
