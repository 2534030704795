import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Eng from '../locales/en/translations.json'
import Hin from '../locales/hin/translations.json'
import Jap from '../locales/jap/translations.json'

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      translations: Eng,
    },
    hin: {
      translations: Hin,
    },
    jap: {
      translations: Jap,
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
})
i18n.languages = ['en', 'hin', 'jap']
export default i18n
