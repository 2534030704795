import React, { useEffect, useState } from 'react'
import classes from '../../assets/css/registerForm.module.css'
import classesDlg from '../../pages/Campus-Layout/Awards/Awards.module.scss'
import { Dialog } from 'primereact/dialog'
import { AutoComplete } from 'primereact/autocomplete'
import * as _ from 'lodash'
import { getRequest } from '../../services/HttpService/HttpService'
import { ButtonComponent } from '../Button/Button'
import { optionsInterface } from '../../schemas/GlobalSchema'
import { Chip } from 'primereact/chip'

export const SkillsComponent: React.FC<{ skills: string; updateSkills: any }> = ({ skills, updateSkills }) => {
  const [visible, setvisible] = useState(false)
  const [selectedSkills, setselectedSkills] = useState<optionsInterface[]>([])
  const [suggestionsArray, setsuggestionsArray] = useState<optionsInterface[]>([])
  const [originalSuggestionsArray, setoriginalSuggestionsArray] = useState<optionsInterface[]>([])
  const [showSelectedSkills, setshowSelectedSkills] = useState<optionsInterface[]>([])

  const greyedOutStyleObj = { display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#807f7fd9' }
  const chipStyleObj = {
    width: 'auto',
    margin: '0.2rem',
    backgroundColor: '#f3f2f2',
    color: '#6c6a6a',
    borderRadius: '10px',
  }
  const saveSkillsHandler = () => {
    setshowSelectedSkills(selectedSkills)
    updateSkills(_.map(selectedSkills, 'value').toString())
    setvisible(false)
  }
  const dialogFooter = (
    <div>
      <ButtonComponent type='success' label='Save' submitEvent={saveSkillsHandler}></ButtonComponent>
    </div>
  )

  useEffect(() => {
    getRequest('/master/skills', {})
      .then((response: any) => {
        const data = (response && response.data && response.data.data) || []
        data.forEach((item: any) => {
          item.label = item.name
          item.value = item.id.toString()
        })
        setoriginalSuggestionsArray(data)
      })
      .catch((error: any) => {
        // if (error && error.response && error.response.data && error.response.data.message)
        //     ToasterService.show(error.response.data.message, 'error');
      })
  }, [])

  useEffect(() => {
    const receivedSkills = (skills && skills.split(',')) || [] //comma seperated string received from parent component
    const receivedSkillsArray: optionsInterface[] = [] //creating label value array to pass in autocmplete value
    originalSuggestionsArray.forEach((item: any) => {
      // (item.label = item.name), (item.value = item.id.toString());
      const skillIndex = receivedSkills.indexOf(item.value)
      if (skillIndex > -1) {
        receivedSkillsArray.push(item)
        receivedSkills.splice(skillIndex, 1)
      }
    })
    receivedSkills.forEach((item: any) => {
      receivedSkillsArray.push({ label: item, value: item })
    })
    setselectedSkills(receivedSkillsArray)
    setshowSelectedSkills(receivedSkillsArray)
  }, [skills])

  const searchSuggestions = (event: any) => {
    setTimeout(() => {
      let filteredRecords: optionsInterface[] = []
      if (!event.query.trim().length) {
        filteredRecords = [...originalSuggestionsArray]
      } else {
        filteredRecords = originalSuggestionsArray.filter((item) => {
          return item.label.toLowerCase().trim().startsWith(event.query.toLowerCase().trim())
        })
      }

      setsuggestionsArray([...filteredRecords])
    }, 250)
  }

  const handleKeyPress = (event: any): void => {
    if (event.code === 'Enter') {
      const tempSelectedSkills = _.cloneDeep(selectedSkills)
      const duplicateRecord = _.find(
        tempSelectedSkills,
        (skill) => skill.label.toLowerCase().trim() === event.target.value.toLowerCase().trim(),
      )
      if (!duplicateRecord) {
        tempSelectedSkills.push({ label: event.target.value, value: event.target.value })
        event.target.value = null
        setselectedSkills(tempSelectedSkills)
      }
    }
  }

  return (
    <>
      <div className={classes['campus-registration-right']}>
        <div className={classes['repeat-seciton']}>
          <h2 style={{ cursor: 'pointer' }} onClick={() => setvisible(true)}>
            Key Skills
            <i className='fa fa-plus' aria-hidden='true' style={{ float: 'right' }}></i>
          </h2>
          {!showSelectedSkills || !showSelectedSkills.length ? (
            <span style={{ ...greyedOutStyleObj, ...{ padding: '0px 0px 10px' } }}>
              Start adding your area of expertise/specialization!!
              <i className='fas fa-certificate ' style={{ paddingLeft: '3px' }}></i>
            </span>
          ) : (
            <div className='row' style={{ padding: '5px', margin: '0px', maxHeight: '20vh', overflow: 'auto' }}>
              {_.map(showSelectedSkills, (skill: optionsInterface, index: number) => {
                return <Chip label={skill.label} key={index} style={chipStyleObj} />
              })}
            </div>
          )}
        </div>
        {/* <Dialog
                header="Key Skills"
                visible={visible}
                style={{ width: '50vw' }}
                modal
                onHide={() => setvisible(false)}
                footer={dialogFooter}
                className="custom-skill-p"
            >
                <div className="row">
                    <div className="col-md-12">
                        <p style={greyedOutStyleObj}>
                            Let us know what you are known for e.g. Javscript,HTML,CSS etc. so that we can show you best
                            job recommendations!! Hit 'Enter' to add new skills.
                        </p>
                        <AutoComplete
                            value={selectedSkills}
                            suggestions={suggestionsArray}
                            // placeholder="Start typing..."
                            completeMethod={searchSuggestions}
                            field="label"
                            multiple
                            onChange={(e) => setselectedSkills(e.value)}
                            onKeyPress={handleKeyPress}
                            // appendTo="self"
                        />
                    </div>
                </div>
            </Dialog> */}
      </div>

      {visible ? (
        <div className={classesDlg['edit-campus-popup']}>
          <div className={classesDlg['edit-campus-popup-inner']}>
            <h2>
              Key Skills
              <span className='Close-p' onClick={() => setvisible(false)} style={{ cursor: 'pointer' }}>
                <i className='fas fa-times'></i>
              </span>
            </h2>
            <div className={classesDlg['edit-campus-popup-pd']}>
              <div className={classesDlg['overview-upload-from']}>
                <div className='row'>
                  <div className='col-md-12'>
                    <p style={greyedOutStyleObj}>
                      Let us know what you are known for e.g. Javscript,HTML,CSS etc. so that we can show you best job recommendations!! Hit
                      'Enter' to add new skills.
                    </p>
                    <AutoComplete
                      value={selectedSkills}
                      suggestions={suggestionsArray}
                      // placeholder="Start typing..."
                      completeMethod={searchSuggestions}
                      field='label'
                      multiple
                      onChange={(e) => setselectedSkills(e.value)}
                      onKeyPress={handleKeyPress}
                      // appendTo="self"
                    />
                  </div>

                  <div className='col-md-12'>
                    <ButtonComponent type='success' label='Save' submitEvent={saveSkillsHandler}></ButtonComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}
