import * as _ from 'lodash'
import * as mime from 'mime-types'
import moment from 'moment'
import { BehaviorSubject } from 'rxjs'
import { AuthService } from '../AuthService/AuthService'
import { getRequest } from '../HttpService/HttpService'
import { ToasterService } from '../ToasterService/ToasterService'

interface Iobject {
  [key: number]: any
}
export interface scheduleObject {
  eventDate: string
  eventType: string
  companyName: string
  companyLogo: string
}

export interface groupedScheduleObject {
  [key: string]: scheduleObject[]
}

const countryList = new BehaviorSubject(null)
const countryWiseStates = new BehaviorSubject<Iobject>({})
const stateWiseCities = new BehaviorSubject<Iobject>({})
const userSchedule = new BehaviorSubject<groupedScheduleObject | null>(null)
const upcomingEvents = new BehaviorSubject<any[]>([])
const upcomingAssessments = new BehaviorSubject<any[]>([])

export const GlobalDataService = {
  countryList: countryList,
  countryWiseStates: countryWiseStates,
  stateWiseCities: stateWiseCities,
  userSchedule: userSchedule,
  upcomingEvents: upcomingEvents,
  upcomingAssessments: upcomingAssessments,

  getCountryData: () => {
    getRequest('/master/countries', {}).then(
      (response: any) => {
        if (response.data && response.data.data) {
          const countries: any = (response.data.data.counties && JSON.parse(JSON.stringify(response.data.data.counties))) || []
          const states: any = (response.data.data.states && JSON.parse(JSON.stringify(response.data.data.states))) || []
          const cities: any = (response.data.data.cities && JSON.parse(JSON.stringify(response.data.data.cities))) || []

          countries.forEach((element: any) => {
            element.label = element.name
            element.value = element.id.toString()
            element.states = []
          })
          states.forEach((element: any) => {
            element.label = element.name
            element.value = element.id.toString()
            element.cities = []
          })
          cities.forEach((element: any) => {
            element.label = element.name
            element.value = element.id.toString()
          })

          const statesGroupedData: any = _.groupBy(states, 'country_id')
          const citiesGroupedData: any = _.groupBy(cities, 'state_id')

          countryList.next(countries)
          countryWiseStates.next(statesGroupedData)
          stateWiseCities.next(citiesGroupedData)
        }
      },
      (error: any) => {},
    )
  },
  getUserScheduleData: () => {
    getRequest('/reqvisits/bookedslot', {}).then(
      (response: any) => {
        if (response.data && response.data.data) {
          const scheduleData = JSON.parse(JSON.stringify(response.data.data))
          const updatedData: scheduleObject[] = []
          _.each(scheduleData, (item) => {
            item.formattedDate = item.date_of_assessment && moment(item.date_of_assessment)
            updatedData.push(
              ...[
                {
                  eventDate: moment(item.date_of_assessment).format('YYYY-MM-DD'),
                  eventType: 'assessment',
                  companyName: item.name,
                  companyLogo: item.client_logo,
                },
                {
                  eventDate: item.start_date,
                  eventType: 'visit',
                  companyName: item.name,
                  companyLogo: item.client_logo,
                },
              ],
            )

            let tempDate = moment(item.start_date).clone().add(1, 'days')

            while (moment(tempDate) <= moment(item.end_date)) {
              updatedData.push({
                eventDate: tempDate.format('YYYY-MM-DD'),
                eventType: 'visit',
                companyName: item.name,
                companyLogo: item.client_logo,
              })
              tempDate = moment(tempDate).add(1, 'days')
            }
          })
          const groupedData: groupedScheduleObject = _.groupBy(updatedData, 'eventDate') || {}
          userSchedule.next(groupedData)

          const filteredUpcomingAssessmentData = _.filter(
            scheduleData,
            (item) => item.date_of_assessment && moment(item.date_of_assessment).format('YYYY-MM-DD') >= moment().format('YYYY-MM-DD'),
          )
          const sortedUpcomingAssessmentsData = _.sortBy(filteredUpcomingAssessmentData, ['formattedDate'])

          upcomingAssessments.next(sortedUpcomingAssessmentsData)
          const filteredUpcomingEventsData = _.filter(scheduleData, (item) => moment(item.end_date) > moment())
          _.each(filteredUpcomingEventsData, (item) => {
            item.startDate = moment(item.start_date)
            item.endDate = moment(item.end_date)
          })
          const sortedUpcomingEventsData = _.sortBy(filteredUpcomingEventsData, ['startDate', 'endDate'])
          upcomingEvents.next(sortedUpcomingEventsData)
        }
      },
      (error: any) => {},
    )
  },
  downloadUserResume: () => {
    const fileName = AuthService.userInfo && AuthService.userInfo.value !== null && AuthService.userInfo?.value.resume_file_name
    const userInfo = AuthService.userInfo && AuthService.userInfo.value
    let resumeName = userInfo.resume_file_name && userInfo.resume_file_name.split('_')
    resumeName.length && resumeName.shift()
    resumeName = resumeName.join('_')

    if (fileName) {
      getRequest('files', {
        params: { q: 'resume', f: fileName },
        responseType: 'blob',
      }).then(
        (response: any) => {
          const data = new Blob([response.data], { type: 'application/pdf' })
          const blobURL = window.URL.createObjectURL(data)
          const tempLink = document.createElement('a')
          tempLink.href = blobURL
          tempLink.setAttribute('download', resumeName)
          tempLink.click()
          // window.open(response.data);
        },
        (error: any) => {},
      )
    } else {
      ToasterService.show('Resume not found!', 'error')
    }
  },
  getPassoutYearDropdown: () => {
    const currentYear = moment().year()
    const passoutYearArr = [
      { label: currentYear + 1, value: currentYear + 1 },
      { label: currentYear, value: currentYear },
    ]
    for (let i = 1; i < 5; i++) {
      passoutYearArr.push({ label: currentYear - i, value: currentYear - i })
    }
    return passoutYearArr
  },
  getFileBlobURL: (fileName: string, qValue: string, filetype?: string) => {
    if (fileName) {
      const mimeType = mime.lookup(fileName?.split('.')?.pop() || '')
      return new Promise((resolve, reject) => {
        getRequest('files', {
          params: { q: qValue, f: fileName },
          responseType: 'blob',
        })
          .then(
            (response: any) => {
              const data = new Blob([response.data], { type: mimeType || '' })
              const blobURL = window.URL.createObjectURL(data)
              resolve(blobURL)
            },
            (error: any) => {
              reject(error)
            },
          )
          .catch((err: any) => reject(err))
      })
    } else {
      ToasterService.show('File Name not found!', 'error')
    }
  },
  getFileObj: (fileName: string, qValue: string, filetype?: string) => {
    if (fileName) {
      const mimeType = mime.lookup(fileName?.split('.')?.pop() || '')
      return new Promise((resolve, reject) => {
        getRequest('files', {
          params: { q: qValue, f: fileName },
          responseType: 'blob',
        })
          .then(
            (response: any) => {
              const data = new Blob([response.data], { type: mimeType || '' })
              const fileName2 = fileName.split('_')?.pop() || ''
              const myFile = new File([data], fileName2, {
                type: data.type,
              })
              //const fileURL = window.URL.createObjectURL(myFile);
              resolve(myFile)
            },
            (error: any) => {
              reject()
            },
          )
          .catch((err: any) => reject(err))
      })
    } else {
      ToasterService.show('File Name not found!', 'error')
    }
  },
  fileDownload: (fileName: string, qValue: string, filetype?: string, showError?: boolean) => {
    if (fileName) {
      const mimeType = mime.lookup(fileName?.split('.')?.pop() || '')
      getRequest('files', {
        params: { q: qValue, f: fileName },
        responseType: 'blob',
      })
        .then((response: any) => {
          const data = new Blob([response.data], { type: mimeType || '' })
          const url = window.URL.createObjectURL(data)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName?.split('_')?.pop() || 'downloade-file')
          document.body?.appendChild(link)
          link.click()
          link.parentNode?.removeChild(link)
        })
        .catch((err: any) => {
          if (showError) {
            ToasterService.show('File Not Found.', 'error')
          }
        })
    } else {
      ToasterService.show('File Name not found!', 'error')
    }
  },
}
