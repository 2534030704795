import { CAMPUSLAYOUT } from '../../navigation/CONSTANTS'

export const DASHBOARD = `${CAMPUSLAYOUT}/dashboard`
export const EDITCAMPUSPROFILE = `${CAMPUSLAYOUT}/profile/edit-campus-details`
export const AWARDS = `${CAMPUSLAYOUT}/profile/awards`
export const ACHIEVEMENTS = `${CAMPUSLAYOUT}/profile/achievements`
export const OVERVIEW = `${CAMPUSLAYOUT}/profile/overview`
export const ALUMINI = `${CAMPUSLAYOUT}/profile/alumini`
export const VIEWREQUISITIONS = `${DASHBOARD}/view-requisitions`
export const VISITDETAILS = `${DASHBOARD}/visit-details`
export const MYDRIVEDETAILS = `${DASHBOARD}/mydrive-details`
export const MYCAMPUSDRIVEDETAILS = `${DASHBOARD}/campus-drive-details`
export const INVITES = `${DASHBOARD}/invites`
export const CANDIDATES = `${CAMPUSLAYOUT}/candidates`
export const ANALYTICS = `${CAMPUSLAYOUT}/analytics`
export const VERIFICATION = `${CAMPUSLAYOUT}/verification`
export const USERS = `${CAMPUSLAYOUT}/users`
export const OPENJOBS = `${DASHBOARD}/open-jobs`
export const COMPANIES = `${CAMPUSLAYOUT}/companies`
export const CONFIG = `${CAMPUSLAYOUT}/config`
export const Create_Job = `${COMPANIES}/share-job`
export const SHARE_JOB_HISTORY = `${COMPANIES}/share-job-history`
export const CONTEST = `${CAMPUSLAYOUT}/contest`
export const CREATE_CONTEST = `${CAMPUSLAYOUT}/contest/create-contest/:id`
export const REGISTERED_CANDIDATES = `${CAMPUSLAYOUT}/contest/ongoing/registered-candidate`
export const CONTEST_HISTORY = `${CAMPUSLAYOUT}/contest/ongoing/contest-history/:contestID`
export const LANDING_PAGE = `${CAMPUSLAYOUT}/contest/landing-page/:id`
export const TIMELINE = `${CAMPUSLAYOUT}/timeline`
