import { MultiSelect } from 'primereact/multiselect'
import React from 'react'
import { optionsObj } from '../../../schemas/FormField'
import Label from '../Label/index'
import classes from './MultiSelect.module.scss'

// interface optionsObj {
//     label: string;
//     value: string;
// }

interface PropsInterface {
  inputtype: 'multiSelect'
  label?: string
  key: string
  value: string[] | null
  id: string
  changed: any
  blurred: any
  options: optionsObj[]
  formName?: string
  disable: boolean
  requiredLabel: boolean
  labelRequire?: boolean
  placeholder?: string
  error?: any
  touched?: any
}

export const MultiSelectComponent: React.FC<PropsInterface> = (props) => {
  let inputElement = null
  const { labelRequire = false, placeholder = '' } = props
  const errorMessageStyle: any = {
    fontSize: 10,
    textAlign: 'left',
  }

  const style: any = {
    width: '100%',
    borderRadius: 5,
    fontSize: 12,
    border: '1px solid #ced4da',
    borderBottom: '1px solid #ced4da',
    // display: display ? display : '',
  }

  const getErrorMessageStyle = () => {
    if (props.touched && props?.value?.length === 0) return { ...errorMessageStyle, color: 'orange' }
    if (props.touched && props.error) return { ...errorMessageStyle, color: 'red' }
    //if(touched) return {...errorMessageStyle,color:'green'}
    return style
  }

  // for getting the message based on error/touched
  const getErrorMessage = () => {
    if (props.touched && props.error) return props.error
    //if(touched) return 'Success'
    return undefined
  }

  switch (props.inputtype) {
    case 'multiSelect':
      inputElement = (
        <MultiSelect
          id={props.id + props.formName}
          value={props.value}
          options={props.options}
          onChange={(e) => props.changed(e.value, props.id)}
          onBlur={() => props.blurred(props.id)}
          filter
          disabled={props.disable}
          className={classes['container']}
          placeholder={placeholder}
        />
      )

      break
  }
  return (
    <div className={classes['custom-multiselect']}>
      {props.label ? <Label label={props.label} required={props.requiredLabel} /> : null}
      <span className={'p-float-label ' + (props.requiredLabel ? 'required-label' : null)}>{inputElement}</span>
      {getErrorMessage() && <div style={getErrorMessageStyle()}>{getErrorMessage()}</div>}
    </div>
  )
}
