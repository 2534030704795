import React, { Suspense } from 'react'
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'
import * as RouteConsts from './CONSTANTS'
import { DynamicIdComponent } from '../components/UI'
import { ProtectedRoute } from '../components/UI'
import { Spinner } from '../components/UI'
import { ConditionalProtectedRoute } from '../components/ConditionalProtectedRoute/ConditionalProtectedRoute'
import { ProtectedRoutePolestar } from '../components/ProtectedRoute/ProtectedRouteForPolestar'

const WelcomeLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/Welcome/Welcome'))
})
const LoginLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/Login/Login'))
})
const SignUpLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/SignUp/SignUp'))
})
const JobFairLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/JobFair/JobFair'))
})
const CampusSignUpLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/CampusSignup/CampusSignup'))
})
const VerifyCodeLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/Verifycode/Verifycode'))
})

const ForgetPasswordLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/ForgetPassword/ForgetPassword'))
})
const ResetPasswordLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/ResetPassword/ResetPassword'))
})

const CandidateRegisterLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/CandidateRegister/CandidateRegister'))
})

const CampusRegisterLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/CampusRegister/CampusRegister'))
})

const CampusRegisterInfoLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/CampusRegisterInfo/CampusRegisterInfo'))
})

const RoleLoginLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/RoleLogin/RoleLogin'))
})

const TestLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/Test/Test'))
})
const PlayGameLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/Layout/Contest/PlayGames/PlayGames'))
})
// const RewardLazyComponent = React.lazy(() => {
//   return new Promise<void>((resolve) => {
//       resolve();
//   }).then(() => import('../pages/Reward/Reward'));
// });

const CampusApprovalLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/CampusApproval/CampusAprroval'))
})

const LayoutLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/Layout/Layout'))
})

const CampusLayoutLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/Campus-Layout/Campus-Layout'))
})

// superAdmin Layout
const SuperAdminLayoutLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/SuperAdminLayout/index'))
})

const LoginAsEmployee = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/Login/LoginAsEmployee/loginAsEmployee'))
})

const WelcomeAsEmployee = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/Welcome/WelcomeAsCandidate/welcomeAsCandidate'))
})

const AllOpeningPage = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/Welcome/AllOpeiningPage/allOpening'))
})

const EmployeeAdminLayoutLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/Employee/employee'))
})

const ContestRegister = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/ContestRegister/contestRegister'))
})

const ContestRegisterUser = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/ContestRegisterUser/contestRegisterUser'))
})
const JobRegisterUser = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/JobRegisterUser/JobRegisterUser'))
})
const ResumeOpener = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/ResumeOpener/index'))
})
const Unsubscribe = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/Unsubscribe/Unsubscribe'))
})
const PassportAuth = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/Login/PassportAuth/PassportAuth'))
})

const OtpPageLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/OTPPage/OTPPage'))
})

const CreateNewPasswordLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/CreateNewPassword/CreateNewPassword'))
})

const ContestDetailPageLazyComponent = React.lazy(() => {
  return new Promise<void>((resolve) => {
    resolve()
  }).then(() => import('../pages/Contest-Share-Page/ContestSharePage'))
})

export const RouterConfig: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={RouteConsts.WELCOME_AS_CANDIDATE}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <WelcomeAsEmployee></WelcomeAsEmployee>
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path={'/SocialAuth'}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <PassportAuth />
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path={RouteConsts.WELCOME_ALL}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <AllOpeningPage></AllOpeningPage>
            </Suspense>
          )}
        ></Route>
        <Route exact path='/component/:id' component={DynamicIdComponent}></Route>

        <Route
          exact
          path={RouteConsts.WELCOME}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <WelcomeLazyComponent></WelcomeLazyComponent>
            </Suspense>
          )}
        ></Route>

        <Route
          exact
          path={RouteConsts.ROLELOGIN}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <RoleLoginLazyComponent></RoleLoginLazyComponent>
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path={RouteConsts.TEST + '/:contestid' + '/:candid' + '/:level' + '/:tabSwitchAllowed'}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <TestLazyComponent></TestLazyComponent>
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path={RouteConsts.PLAYGAMES + '/:contestid' + '/:candid' + '/:level' + '/:tabSwitchAllowed' + '/:practice?' + '/:puzzleData?'}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <PlayGameLazyComponent></PlayGameLazyComponent>
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path={RouteConsts.CONTESTREGISTER + '/:contestid' + '/:token' + '/:subDomain'}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <ContestRegister></ContestRegister>
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path={RouteConsts.CONTESTREGISTERUSER + '/:contestid'}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <ContestRegisterUser></ContestRegisterUser>
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path={RouteConsts.JOBREGISTERUSER + '/:jobID'}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <JobRegisterUser></JobRegisterUser>
            </Suspense>
          )}
        ></Route>
        {/* <Route
                    exact
                    path={RouteConsts.REWARD}
                    render={() => (
                        <Suspense fallback={<Spinner />}>
                            <RewardLazyComponent></RewardLazyComponent>
                        </Suspense>
                    )}
                ></Route> */}
        <Route
          exact
          path={RouteConsts.LOGIN}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <LoginLazyComponent></LoginLazyComponent>
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path={RouteConsts.LOGINASEMPLOYEE}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <LoginAsEmployee></LoginAsEmployee>
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path={RouteConsts.JOBFAIR}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <JobFairLazyComponent></JobFairLazyComponent>
            </Suspense>
          )}
        ></Route>
        {/* Candidate Signup */}
        <Route
          exact
          path={RouteConsts.SIGNUP}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <SignUpLazyComponent></SignUpLazyComponent>
            </Suspense>
          )}
        ></Route>
        {/* Campus Signup */}
        <Route
          exact
          path={RouteConsts.CAMPUSSIGNUP}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <CampusSignUpLazyComponent></CampusSignUpLazyComponent>
            </Suspense>
          )}
        ></Route>

        <Route
          exact
          path={RouteConsts.FORGETPASSWORD}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <ForgetPasswordLazyComponent></ForgetPasswordLazyComponent>
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path={RouteConsts.RESUMEPAGE + '/:resumeID'}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <ResumeOpener></ResumeOpener>
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path={RouteConsts.UNSUBSCRIBE}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <Unsubscribe></Unsubscribe>
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path={RouteConsts.RESETPASSWORD}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <ResetPasswordLazyComponent></ResetPasswordLazyComponent>
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path={RouteConsts.OTPPAGE}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <OtpPageLazyComponent></OtpPageLazyComponent>
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path={RouteConsts.REDIRECTPAGE + '/:id'}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <ContestDetailPageLazyComponent></ContestDetailPageLazyComponent>
            </Suspense>
          )}
        ></Route>
        <Route
          exact
          path={RouteConsts.CREATENEWPASSWORD}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <CreateNewPasswordLazyComponent></CreateNewPasswordLazyComponent>
            </Suspense>
          )}
        ></Route>
        {/* <Route
                    exact
                    path={RouteConsts.EMPLOYEE}
                    render={() => (
                        <Suspense fallback={<Spinner />}>
                            <EmployeeAdminLayoutLazyComponent></EmployeeAdminLayoutLazyComponent>
                        </Suspense>
                    )}
                ></Route> */}
        <ConditionalProtectedRoute path={RouteConsts.VERIFYCODE} component={VerifyCodeLazyComponent} exact />

        {/* <ProtectedRoute path={RouteConsts.HOME} component={HomeLazyComponent} exact /> */}
        <ProtectedRoute path={RouteConsts.CANDIDATEREGISTER} component={CandidateRegisterLazyComponent} exact />
        <ProtectedRoute path={RouteConsts.CAMPUSREGISTER} component={CampusRegisterLazyComponent} exact />
        <ProtectedRoute path={RouteConsts.CAMPUSREGISTERINFO} component={CampusRegisterInfoLazyComponent} exact />
        {/* <ProtectedRoute path={RouteConsts.ROLELOGIN} component={RoleLoginLazyComponent} exact /> */}
        <ProtectedRoute path={RouteConsts.CAMPUSAPPROVAL} component={CampusApprovalLazyComponent} exact />
        {/* Campus Dashboard */}
        <ProtectedRoute path={RouteConsts.CAMPUSLAYOUT} component={CampusLayoutLazyComponent}></ProtectedRoute>
        {/* Employee Home */}
        <ProtectedRoutePolestar path={RouteConsts.EMPLOYEE} component={EmployeeAdminLayoutLazyComponent}></ProtectedRoutePolestar>
        {/* superAdmin DashBoard */}
        <ProtectedRoute path={RouteConsts.SUPERADMIN} component={SuperAdminLayoutLazyComponent}></ProtectedRoute>
        {/* Candidate Dashboard */}
        <ProtectedRoute path={RouteConsts.CANDIDATELAYOUT} component={LayoutLazyComponent}></ProtectedRoute>
        {/* <Redirect from={RouteConsts.ROOT} to={NavigateUserService.getRoute()} exact /> */}
        <Redirect from='*' to={RouteConsts.WELCOME} />
      </Switch>
    </BrowserRouter>
  )
}
